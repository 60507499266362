$primary:       rgb(226,0,26);
$secondary:     rgb(62,57,61);
$success:       rgb(56,169,98);
$info:          rgb(0,164,200);
$warning:       rgb(252,297,29);
$danger:        #E13636;
$link-color:    rgb(62,57,61) !default;

@import '~admin-lte/build/scss/AdminLTE.scss';



@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/scss/regular.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';
@import '~@fortawesome/fontawesome-free/scss/brands.scss';


.form-control, .form-control-sm {
    border-radius: 0;
}
.form-control-sm {
    border: 1px solid lightgray;
}
.vs__dropdown-toggle {
    background: white!important;
    border-radius: 0!important;
}
.btn {
    border-radius: 0;
}
.modal-backdrop {
    position: fixed;
    z-index: 1040;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
}

label {
    /*
    WAT ?
     */
    /*font-size:50px;*/
}

.main-header {
    .phone {
        font-size: 16px;
    }
}

.home {
    .img img {
        width: 100px;
        height: 100px;
        object-fit: contain;
    }
    h3 {
        font-weight: bold;
    }
    .orange {
        // color:white;
        background-color : #ffdecd; /* ffdfce */
    }
    // .orange img {
    //     border : 1px solid #fe4c00;
    // }
    .orange h2 {
        color: #fe4c00;
    }
    .orange .bottom {
        background: #fe4c00;
        color: white;
        bottom: 0;
    }
    .blue {
        // color:white;
        background-color: rgba(23, 162, 184, 0.3);
        // background-color : #17a2b8;
    }
    .blue h2 {
        color: #17a2b8;
    }

    .blue .bottom {
        background-color : #17a2b8;
        color: white;
        bottom: 0;

    }
    .img-card img {
        height: 280px;
        width: 100%;
        object-fit: cover;
    }
    .cta {
        background: #f0f0f0;
        height: 200px;
    }
    #top-img {
        min-height : 400px;
        background: url('../images/header.jpg') no-repeat center center fixed;
        background-size: cover;
    }
    // ---- CSS CARDS
    .card-img {
        height: 200px;
        object-fit: cover;
    }
    .card-link {
        display: none;
    }
    .card-text {
        font-size: 16px;
        line-height: 1.4;
        font-weight: 600;
    }
    .card-img-overlay {
        background-color: rgba(#000, 0.52);
        transition : background-color 0.3s ease-in-out;
    }
    .card:hover .card-img-overlay {
        background-color: rgba(#000, 0.3);
    }
    .card:hover .card-text {
        display : none;
    }
    .btn-hover:hover {
        background: #d40000!important;
        color: white;
        // border-color: white;
    }
    .card:hover .card-link {
        display: block;
    }
    .profile .icon.person:hover {
        background: #17a2b8;
        padding: 5px;
        border-radius: 50px;
    }

    .profile .card-img-overlay {
        background-color: transparent;
        height: 180px;
        width: 140px;
        border-radius: 100px;
        transition : background-color 0.3s ease-in-out;
    }
    .profile .card:hover .card-img-overlay {
        background-color: rgba(#000, 0.5);
        width: 140px;
        height: 180px;
        border-radius: 100px;
        box-shadow : 0 0.5rem 1rem rgba(0, 0, 0, 0.25) !important;

    }
    .profile .card {
        width: 140px;
        height: 180px;
        border-radius: 100px;
    }
    .profile .card-img {
        height: 180px;
        width: 140px;
        border-radius: 100px;
    }
    .profile .email {
        font-size: 12px;
    }
    .profile .card-img-overlay {
        padding: 0.5rem!important;
        box-shadow : 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    }
    .profile .card-link {
        padding-top: 15px!important;
    }
    @media (min-width: 1380px) {
        .card-link {
            padding: 1.5rem!important;
        }
        .profile .card-link {
            padding: 0!important;
            padding-top: 15px!important;
        }
    }

    @media (max-width: 576px) {
        .cta {
            height : 100%;
        }
        .profile img {
            width: 100px;
            height: 120px;
            margin-left: 20px!important;
            margin-right:0!important;
        }
    }
}

footer {
    background: #454545;
    color: white;
}
.adress {
    font-size:16px;
}

.footer-copyright {
    background: #d40000!important;
    color: white;
}
.footer-copyright a {
    color:white;
    font-weight: bold;
}

.map {
    height:300px; max-width:400px; margin:auto;
}
@media (max-width: 768px) {
    .footer-copyright div {
        text-align: center!important;
    }
}
@media (max-width: 1200px) {
    .page-footer .agences {
        padding-left: 50px;
    }
    .page-footer .map {
        height:400px;
        max-width:400px;
        margin: 0;
    }
}
@media (max-width: 576px) {
    .page-footer .agences {
        padding-left: 0;
    }
}


/** A switch that replaces a checkbox ***/

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 17px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 17px;
}

.slider.round:before {
    border-radius: 50%;
}

.vs__dropdown-menu {
    margin-bottom: 5em;
    z-index: 1140;
}
